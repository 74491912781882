import { PostProcessor } from "../PrintProcessorRegistry";
import { IPrintFunctionParams } from "../../../core/printinterface/PrintFunction";

interface IDeleteColumnParams extends IPrintFunctionParams {
    column?: number;
}

// example -> delete a column of a table
export const deleteColumn: PostProcessor = {
    run: (tableHtml: string, params: IDeleteColumnParams) => {
        const table = $(tableHtml);

        if (typeof params["column"] == "undefined") {
            return "deleteColumn - column missing";
        }

        // iterate over all rows and do the thing
        let rows = table.children().children("tr");

        for (let row of rows.toArray()) {
            const tds = $(row).children("td,th");
            let colNumber = params["column"];

            if (colNumber < 0) {
                colNumber = tds.length + colNumber;
            }

            tds[colNumber].remove();
        }

        return table[0].outerHTML;
    },
};
