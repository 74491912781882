import { IDB } from "../../../core/common/businesslogic/index";
import { ISettingPage } from "../../../core/common/businesslogic/index";
import { ml } from "../../../core/common/matrixlib";
import { IValidationSpec, JsonEditor } from "../../../core/common/UI/JsonEditor";
import { app, globalMatrix, restConnection } from "../../../core/globals";
import {
    XRUserType,
    XRGroupType,
    XRGroupPermissionType,
    XRGetUser_AllUsers_GetUserListAck,
    XRGetGroup_AllGroups_GetGroupListAck,
} from "../../../core/RestResult";
import { IConfigApp } from "./IConfigApp";

export interface IConfigPage {
    getNode(): IDB;
    saveAsync(): JQueryDeferred<unknown>;
    load(pageId: string): void;
    getProject(): string;
    getCategory(): string;
    getField(): string;
    willUnload(): void;
}

export interface IGroupsUsers {
    users: XRUserType[];
    groups: XRGroupType[];
}

export class ConfigPage implements IConfigPage {
    static PROJECT_SETTING_FOLDER_BASE_ID: string = "projectsettings";
    public static PROJECT_SETTING_FOLDER_TYPE: string = "ProjectSettingsFolder";

    protected pageId: string;
    protected simple: JQuery;

    protected allUsers: XRUserType[];
    protected allGroups: XRGroupType[];
    configApp: IConfigApp;

    getNode(): IDB {
        return null;
    }

    saveAsync(): JQueryDeferred<unknown> {
        return null;
    }

    load(pageId: string): void {
        this.pageId = pageId;
    }

    constructor(configApp: IConfigApp) {
        this.configApp = configApp;
    }
    getProject() {
        return this.pageId.split("-")[0];
    }

    getCategory() {
        return this.pageId.split("-")[1];
    }
    getField() {
        return this.pageId.split("-")[2];
    }
    willUnload(): void {}

    protected initPage(
        title: string,
        showAdvancedBtn: boolean,
        showDeleteText: string,
        help: string,
        externalHelp?: string,
        showCopy?: boolean,
    ) {
        app.itemForm.html("");
        let top = $("<div id='top'>").appendTo(app.itemForm);
        top.append(ml.UI.getPageTitle(title));
        let toolBarButtons = $(".toolbarButtons", top);
        toolBarButtons.removeClass("btn-group");

        if (showAdvancedBtn) {
            $('<button data-cy="advanced" class="btn btn-link btn-top">advanced</button>')
                .click((event: JQueryEventObject) => {
                    this.showAdvanced();
                })
                .appendTo(toolBarButtons);
        }

        if (showDeleteText) {
            $('<button data-cy="delete" class="btn btn-link btn-top"><i class="fal fa-trash-alt"/></button>')
                .click((event: JQueryEventObject) => {
                    ml.UI.showConfirm(
                        -100,
                        { title: showDeleteText, ok: "delete", nok: "cancel" },
                        () => {
                            this.doDelete();
                        },
                        () => {},
                    );
                })
                .appendTo(toolBarButtons);
        }

        if (showCopy) {
            $('<button data-cy="copy" class="btn btn-link btn-top"><i class="fal fa-copy"/></button>')
                .click((event: JQueryEventObject) => {
                    this.doCopy();
                })
                .appendTo(toolBarButtons);
        }

        if (externalHelp) {
            $('<button data-cy="eternalHelp" class="btn btn-link btn-top"><i class="fal fa-question-circle"/></button>')
                .click((event: JQueryEventObject) => {
                    window.open("https://urlshort.matrixreq.com/d24/" + externalHelp);
                })
                .appendTo(toolBarButtons);
        }

        $("<div class='inlineHelp'>").appendTo(top).html(help);

        this.simple = $("<div id='simple'>").appendTo(app.itemForm);
    }

    protected showAdvanced(): void {}
    protected doCopy(): void {}
    protected doDelete(): void {}
    protected showSimple(): void {}

    protected showWarning(text: string, id?: string): JQuery {
        if (id == undefined) id = "noId";

        let ret = $(`<div class='configWarning' id='${id}'>`);
        ret.append(`<div><i class="fa fa-exclamation-triangle"></i> Caution </div>`);
        ret.append(`<div class="info">${text}</div>`);

        ret.appendTo(this.simple);

        return ret;
    }
    protected showNote(text: string) {
        let ret = $(`<div class='configNote' >`);
        ret.append(`<div><i class="fa fa-info-circle"></i> Information </div>`);
        ret.append(`<div class="info">${text}</div>`);

        ret.appendTo(this.simple);

        return ret;

        $("<div class='configNote'>")
            .html("<span class='fal fa-info-circle'></span>  " + text)
            .appendTo(this.simple);
    }
    protected showAdvancedCode(code: string, success: (code: string) => void, semanticValidate?: IValidationSpec) {
        let that = this;

        let je = new JsonEditor();
        je.showDialog(
            "Advanced Edit",
            code,
            function (newValue: string) {
                success(newValue);
            },
            semanticValidate,
        );
    }

    protected getProjectSettingLink(setting: string) {
        return (
            globalMatrix.matrixBaseUrl +
            "/adminConfig/" +
            this.getProject() +
            "-" +
            ConfigPage.PROJECT_SETTING_FOLDER_BASE_ID +
            "-" +
            setting
        );
    }
    protected getProjectSettingLinkA(setting: string, name: string) {
        return "<a href='" + this.getProjectSettingLink(setting) + "'>" + name + "</a>";
    }
    protected getCategorySettingLink() {
        return (
            globalMatrix.matrixBaseUrl + "/adminConfig/" + this.getProject() + "-" + this.getCategory() + "-settings"
        );
    }

    protected getTraceSettingLink() {
        return globalMatrix.matrixBaseUrl + "/adminConfig/" + this.getProject() + "-projectsettings-traceability";
    }

    protected getPluginLink() {
        return globalMatrix.matrixBaseUrl + "/adminConfig/extensions";
    }

    //copy from UiTools for admin client!
    // make a unique ID of the numeric group id, this is used to be stored in fields
    protected getGroupId(group: XRGroupType) {
        return ml.UI.SelectUserOrGroup.getGroupId(<XRGroupPermissionType>(<any>group));
    }

    // copy from UiTools for admin client!
    // convert a group id used in mail to italic group name
    // this is used to be displayed in UI
    protected getGroupDisplayNameFromId(groupOrUserId: string) {
        let that = this;

        for (var idx = 0; idx < this.allGroups.length; idx++) {
            if (this.getGroupId(this.allGroups[idx]) == groupOrUserId) {
                let canEdit = that.configApp.canGroupWrite(that.getProject(), this.allGroups[idx].groupId);

                let groupName = canEdit
                    ? this.allGroups[idx].groupName
                    : "<span style='text-decoration:line-through'>" + this.allGroups[idx].groupName + "</span>";

                return "<span class='groupFlag'>group&nbsp;</span>" + groupName;
            }
        }
        let canEdit = that.configApp.canUserWrite(that.getProject(), groupOrUserId);
        return canEdit ? groupOrUserId : "<span style='text-decoration:line-through'>" + groupOrUserId + "</span>";
    }

    // returns the name user id - first last name
    protected combinedName(user: XRUserType) {
        return globalMatrix.ItemConfig.getCombinedName(user);
    }
    // resize dialog
    private resizeUserSelectDlg(dlg: JQuery) {
        let height = dlg.height();
        let top = $(".ms-list", dlg).position().top;

        $(".ms-list", dlg).height(height - top);
    }

    // this adds an <input> control showing the selected users / user groups, when clicking it allows to change the selection in a dialog
    public showUserAndGroupsSelectWithDialog(
        container: JQuery,
        showUsers: boolean,
        showGroups: boolean,
        help: string,
        empty: string,
        selected: string[],
        dialogTitle: string,
        onSelect: (selection: string[]) => void,
    ) {
        let that = this;
        $('<span class="baseControlHelp">' + help + "</span>").appendTo(container);
        let ctrl = $("<div class='baseControl'>").appendTo(container);
        let input = $("<div class='form-control userSelect userSelectAdmin'>").appendTo(ctrl);
        input.html(
            selected && selected.length
                ? selected
                      .map(function (userOrGroup) {
                          return that.getGroupDisplayNameFromId(userOrGroup);
                      })
                      .join(", ")
                : "<span class='emptySelection'>" + empty + "</span>",
        );
        ctrl.click(function (event: JQueryMouseEventObject) {
            that.showSelectDialog(
                selected ? selected : [],
                showUsers,
                showGroups,
                dialogTitle,
                (selection: string[]) => {
                    let selectedUI = selection.map(function (userOrGroup) {
                        return that.getGroupDisplayNameFromId(userOrGroup);
                    });
                    input.html(
                        selectedUI.length ? selectedUI.join(", ") : "<span class='emptySelection'>" + empty + "</span>",
                    );
                    selected = ml.JSON.clone(selection);
                    onSelect(selection);
                },
            );

            return false;
        });
    }

    // opens a dialog to select users
    protected showSelectDialog(
        selectedIn: string[],
        showUsers: boolean,
        showGroups: boolean,
        dialogTitle: string,
        onSelect: (selection: string[]) => void,
    ) {
        let that = this;

        let dlg = $("<div  data-cy='selectDialog'>").appendTo("body");
        let select = $("<select multiple>").appendTo(dlg);

        let selected: string[] = selectedIn ? ml.JSON.clone(selectedIn) : [];

        select.append("<option style='display:none' value='' data-search=''>");
        let users = showUsers ? that.allUsers : [];
        let groups = showGroups ? that.allGroups : [];

        let userSelect = select;
        let groupSelect = select;
        let groupIds = groups.map((g) => that.getGroupId(g));
        if (groups.length && users.length) {
            userSelect = $("<optgroup label='Users'>").appendTo(select);
            groupSelect = $("<optgroup label='User Groups'>").appendTo(select);
        }

        // add to user select
        $.each(users, function (userIdx, user) {
            let name = that.combinedName(user);
            let email = user.email ? user.email : "";
            let searchVal = email + " " + name;
            let sel = selected.indexOf(user.login) != -1 ? " selected" : "";
            let canEdit = that.configApp.canUserWrite(that.getProject(), user.login) ? "1" : "0";

            userSelect.append(
                $(
                    `<option data-cy="${
                        user.login
                    }" data-canedit='${canEdit}' data-search='${searchVal.toLowerCase()}' title='${email}' value='${
                        user.login
                    }' ${sel} >${name}</option>`,
                ),
            );
        });

        $.each(groups, function (groupIdx, group) {
            let groupName = group.groupName;
            let groupId = that.getGroupId(group);
            let sel = selected.indexOf(groupId) != -1 ? " selected" : "";
            let canEdit = that.configApp.canGroupWrite(that.getProject(), group.groupId);
            groupSelect.append(
                $(
                    `<option data-group='group' data-canedit='${canEdit}' data-search='${groupName.toLowerCase()}' value='${groupId}' ${sel} >${groupName}</option>`,
                ),
            );
        });

        // add selected but deleted users/user groups
        let allUsers = users.map((user) => user.login);
        for (let selectedUserOrGroup of selected) {
            if (groupIds.indexOf(selectedUserOrGroup) == -1 && allUsers.indexOf(selectedUserOrGroup) == -1) {
                let entryOfDeleted = $(
                    `<option data-canedit='0' data-search='${selectedUserOrGroup}' data-cy='${selectedUserOrGroup}' title='${selectedUserOrGroup}' value='${selectedUserOrGroup}' selected>${selectedUserOrGroup}</option>`,
                );
                if (selectedUserOrGroup.match(/g_[0-9]+_g/)) {
                    groupSelect.append(entryOfDeleted);
                } else {
                    userSelect.append(entryOfDeleted);
                }
            }
        }

        let showOnly = "groups / users";
        if (!showGroups) showOnly = "users";
        if (!showUsers) showOnly = "groups";

        dlg.dialog({
            autoOpen: true,
            title: dialogTitle ? dialogTitle : "Select",
            width: 716,
            height: 580,
            resizeStop: function () {
                that.resizeUserSelectDlg(dlg);
            },
            modal: true,
            open: function () {
                ml.UI.pushDialog(dlg);
                let showOnlyWrite =
                    '<div class="checkbox" data-cy="showOnlyWrite"  ><label><input type="checkbox" class="showOnlyWrite" checked>Show only ' +
                    showOnly +
                    " with write access</label></div>";
                let showOnlyWriteSel =
                    '<div class="checkbox" data-cy="showOnlyWriteSel"  ><label><input type="checkbox" class="showOnlyWriteSel">Show only ' +
                    showOnly +
                    " with write access</label></div>";
                select.multiSelect({
                    selectableHeader:
                        "<div class='custom-header'>" +
                        showOnlyWrite +
                        "</div><input type='text' class='form-control ms-search' autocomplete='off' placeholder='filter'>",
                    selectionHeader:
                        "<div class='custom-header'>" +
                        showOnlyWriteSel +
                        "</div><input type='text' class='form-control ms-search' autocomplete='off' placeholder='filter'>",
                    afterInit: function () {
                        var wow = this,
                            $selectableSearch = wow.$selectableUl.prev(),
                            $selectionSearch = wow.$selectionUl.prev(),
                            selectableSearchString =
                                "#" + wow.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)",
                            selectionSearchString = "#" + wow.$container.attr("id") + " .ms-elem-selection.ms-selected",
                            selectionOptions = "#" + wow.$container.attr("id") + " .ms-elem-selectable",
                            selectedOptions = "#" + wow.$container.attr("id") + " .ms-elem-ms-selected",
                            selectedOptionsOff = "#" + wow.$container.attr("id") + " .ms-elem-selection";

                        that.markUsersWithoutAccess($(selectionOptions));
                        that.markUsersWithoutAccess($(selectedOptions));
                        that.markUsersWithoutAccess($(selectedOptionsOff));

                        $("li", dlg).each(function (idx, opt) {
                            if ($(opt).data("group")) $(opt).prepend("<span class='groupFlag'>group</span>");
                        });

                        $selectableSearch.on("keyup", function (e: JQueryKeyEventObject) {
                            if (e.which === 40) {
                                wow.$selectableUl.focus();
                                return false;
                            } else {
                                that.filterGroupsAndUsers(
                                    $(selectableSearchString),
                                    $selectableSearch.val().toLowerCase(),
                                    $(".showOnlyWrite").prop("checked"),
                                );
                                return true;
                            }
                        });

                        $selectionSearch.on("keyup", function (e: JQueryKeyEventObject) {
                            if (e.which == 40) {
                                wow.$selectionUl.focus();
                                return false;
                            } else {
                                let searchExpr = $selectionSearch.val().toLowerCase();

                                that.filterGroupsAndUsers(
                                    $(selectionSearchString),
                                    $selectionSearch.val().toLowerCase(),
                                    $(".showOnlyWriteSel").prop("checked"),
                                );

                                return true;
                            }
                        });

                        $(".showOnlyWrite").click(function () {
                            that.filterGroupsAndUsers(
                                $(selectableSearchString),
                                $selectableSearch.val().toLowerCase(),
                                $(".showOnlyWrite").prop("checked"),
                            );
                        });
                        $(".showOnlyWriteSel").click(function () {
                            that.filterGroupsAndUsers(
                                $(selectionSearchString),
                                $selectionSearch.val().toLowerCase(),
                                $(".showOnlyWriteSel").prop("checked"),
                            );
                        });
                        that.filterGroupsAndUsers(
                            $(selectableSearchString),
                            $selectableSearch.val().toLowerCase(),
                            $(".showOnlyWrite").prop("checked"),
                        );
                        that.filterGroupsAndUsers(
                            $(selectionSearchString),
                            $selectionSearch.val().toLowerCase(),
                            $(".showOnlyWriteSel").prop("checked"),
                        );
                    },
                    afterSelect: function (sel: string[]) {
                        selected.push(sel[0]);
                    },
                    afterDeselect: function (sel: string[]) {
                        selected = selected.filter(function (s) {
                            return s != sel[0];
                        });
                    },
                });

                that.resizeUserSelectDlg(dlg);
            },
            close: function () {
                ml.UI.popDialog(dlg);
                dlg.remove();
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        onSelect(selected);
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
        });
    }

    protected markUsersWithoutAccess(lis: JQuery) {
        $.each(lis, function (lidx, li) {
            if ($(li).data("canedit") != "1") $(li).css("text-decoration", "line-through");
        });
    }

    protected filterGroupsAndUsers(lis: JQuery, sex: string, writeOnly: boolean) {
        $.each(lis, function (lidx, li) {
            let hasSex = !sex || $(li).data("search").indexOf(sex) != -1;
            let hasWrite = !writeOnly || $(li).data("canedit") == "1";
            hasSex && hasWrite ? $(li).show() : $(li).hide();
        });
    }

    // store all the current groups and users in page
    protected updateUsersAndGroups(withDetails: boolean): JQueryDeferred<{}> {
        let that = this;

        let res: JQueryDeferred<{}> = $.Deferred();
        restConnection.getServer("user" + (withDetails ? "?details=1" : "")).done(function (allUsersResult) {
            that.allUsers = (allUsersResult as XRGetUser_AllUsers_GetUserListAck).user
                .filter(function (user) {
                    return user.userStatus != "deleted" && user.userStatus != "blocked" && !user.superAdmin;
                })
                .sort(function (a, b) {
                    if (a.login < b.login) return -1;
                    else return 1;
                });

            restConnection.getServer("group" + (withDetails ? "?&details=1" : "")).done(function (allGroupsResult) {
                that.allGroups = (allGroupsResult as XRGetGroup_AllGroups_GetGroupListAck).groups.sort(function (a, b) {
                    if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) return -1;
                    else return 1;
                });

                res.resolve();
            });
        });

        return res;
    }

    initMeta(users: XRUserType[], groups: XRGroupType[], pageId: string) {
        this.pageId = pageId;
        this.allGroups = groups;
        this.allUsers = users;
    }
}

export class GenericAdminPage extends ConfigPage {
    private page: ISettingPage;
    project: string;
    category: string;
    constructor(configApp: IConfigApp, page: ISettingPage) {
        super(configApp);
        this.page = page;
        this.pageId = page.id;
    }

    getNode() {
        if (this.page.getNode) return this.page.getNode();
        else
            return {
                type: this.page.type,
                title: this.page.title,
                id: this.page.id,
                icon: "admin/setting.png",
                children: <IDB[]>[],
            };
    }

    saveAsync() {
        if (this.page.saveAsync) {
            return this.page.saveAsync();
        }

        let res = $.Deferred();
        res.resolve(this.pageId);
        return res;
    }

    load(pageId: string) {
        super.load(pageId);

        let that = this;
        this.project = that.getProject();
        this.category = that.getCategory();

        this.initPage(
            this.page.title + " - " + this.getProject(),
            !!this.page.advanced,
            !!this.page.del ? "Really?" : "",
            this.page.help,
            this.page.externalHelp,
        );

        if (this.page.render) this.page.render(this.simple);
    }

    protected showAdvanced(): void {
        if (this.page.advanced) this.page.advanced();
    }

    protected doDelete(): void {
        if (this.page.del) this.page.del();
    }
}
