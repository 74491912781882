import { PostProcessor } from "../PrintProcessorRegistry";

// example to delete empty rows
export const removeEmptyRows: PostProcessor = {
    run: (rendering: string) => {
        const table = $(rendering);

        $("tr", table).each((idx, row) => {
            if (!$(row).text()) {
                $(row).remove();
            }
        });

        return table[0].outerHTML;
    },
};
