export enum TodoTypes {
    needSignature = "needSignature",
    needReview = "needReview",
    signaturesDone = "signaturesDone",
    signatureRejected = "signatureRejected",
    reviewsDone = "reviewsDone",
    needTraining = "needTraining",
    user = "user",
    needApprove = "needApprove",
    approveDone = "approveDone",
}
