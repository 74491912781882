import { XRFieldTypeAnnotated } from "../../core/common/businesslogic/ItemConfiguration";
import { IFieldHandler } from "../../core/common/businesslogic/FieldHandlers/IFieldHandler";
import { Item } from "./Item";

export { Field };

/**
 * A Field represents a field in an Item in a Project on a Matrix Instance. The Field contains
 * the data for a given field, along with knowledge about the configuration of that field, as
 * given by the Category settings for Items of that particular category.
 *
 * The end user reads and changes the data in a field through a Field Handler. There is a
 * unique field handler for each field type. Consult documentation for a table mapping field
 * types to field handlers.
 */
class Field {
    private oldData?: string;

    constructor(
        private item: Item,
        private config: XRFieldTypeAnnotated,
        private handler: IFieldHandler,
    ) {
        if (this.config.fieldType != this.handler.getFieldType()) {
            throw new Error(
                `Field type ${this.config.fieldType} doesn't match handler field type ${this.handler.getFieldType()}`,
            );
        }

        // Also implemented for dhfFieldHandler and dashboardFieldHandler because it uses the cached data stored in the database.
        this.oldData = handler.getData();
    }

    /**
     * Get the Item which contains this field.
     * @returns the containing Item
     */
    getItem(): Item {
        return this.item;
    }

    getHandlerRaw(): IFieldHandler {
        return this.handler;
    }

    getHandler<T>(): T {
        // return the handler cast appropriately given the type of the field.
        // TODO: check this somehow.
        return <T>this.handler;
    }

    getFieldType(): string {
        return this.config.fieldType;
    }
    getFieldId(): number {
        return this.config.id;
    }
    getFieldName(): string {
        return this.config.label;
    }
    getFieldConfigParameter(name: string): unknown {
        return this.config.parameterJson?.[name];
    }

    needsSave(): boolean {
        return this.handler.getData() !== this.oldData;
    }
}
