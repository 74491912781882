// eslint-disable-next-line no-unused-vars

import {
    app,
    IBaseControlOptions,
    IItem,
    IPluginPanelOptions,
    IProjectPageParam,
    matrixsdk,
    IPlugin,
    PrintProcessor,
} from "../client";
import { ControlCoreBase, IControlOptions, IPluginFieldHandler } from "./ControlCore";
import {
    IDashboardParametersBase,
    IExternalPlugin,
    IPluginFieldValueBase,
    IPluginMenuAction,
    IPluginSearch,
    IProjectSettingsBase,
    IServerSettingsBase,
    ITinyMenu,
    ITool,
} from "./interfaces";
import { ISettingPage } from "../../core/common/businesslogic/index";
import { IFieldDescription } from "../../core/ProjectSettings";

export class PluginCore implements IPlugin {
    Plugin: IExternalPlugin<
        IServerSettingsBase,
        IProjectSettingsBase,
        IPluginFieldHandler<IPluginFieldValueBase>,
        IPluginFieldValueBase,
        IDashboardParametersBase
    >;
    static getServerSetting(settingId: string, defaultValue: unknown): IServerSettingsBase {
        let val = "";
        for (let idx = 0; idx < matrixsdk.serverConfig().customerSettings.length; idx++) {
            if (matrixsdk.serverConfig().customerSettings[idx].key == settingId) {
                val = matrixsdk.serverConfig().customerSettings[idx].value;
            }
        }
        return val ? JSON.parse(val) : defaultValue;
    }

    /** if false the plugin will not be loaded (for debugging) */
    public isDefault = true;

    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    public enabledInContext = true;

    constructor(
        plugin: IExternalPlugin<
            IServerSettingsBase,
            IProjectSettingsBase,
            IPluginFieldHandler<IPluginFieldValueBase>,
            IPluginFieldValueBase,
            IDashboardParametersBase
        >,
    ) {
        this.Plugin = plugin; // save plugin for later use
        console.debug(`Constructing ${this.Plugin.PLUGIN_NAME}`);
    }

    // ------------------------------------------------ initialization calls  ------------------------------------------------

    initProject(project: string) {
        this.onInitProject(project);
    }
    // to be overwritten
    onInitProject(project: string) {
        this.Plugin.onInitProject(project);
    }

    initItem(_item: IItem, _jui: JQuery) {
        this.onInitItem(_item);
    }
    // to be overwritten
    onInitItem(_item: IItem) {
        this.Plugin.onInitItem(_item);
    }

    // ------------------------------------------------ item menu ------------------------------------------------

    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    enableToolMenu = (ul: JQuery, _hook: number) => {
        return this.Plugin.enableToolMenu(ul, _hook);
    };

    // by default plugins add none or one item to the tool menu of displayed items. This class can be overwritten if needed
    async updateMenu(ul: JQuery, hook: number) {
        if (this.Plugin.getConfig().menuToolItem.enabled && this.enableToolMenu(ul, hook)) {
            const m: ITool = await this.Plugin.getToolAsync();

            if (m && m.showMenu(app.getCurrentItemId())) {
                const li = $(`<li><a>${this.Plugin.getConfig().menuToolItem.title}</a></li>`).on("click", async () => {
                    const m = await this.Plugin.getToolAsync();
                    m.menuClicked(app.getCurrentItemId());
                });
                ul.append(li);
            }
        }
    }

    // ------------------------------------------------ control (fields) implementation ------------------------------------------------
    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    enableControl = (fieldType: string) => {
        return this.enabledInContext;
    };

    // this method is called to see if a plugin defines or overwrites a field type
    supportsControl(fieldType: string): boolean {
        return (
            this.enableControl(fieldType) &&
            fieldType == this.Plugin.getConfig().field.fieldType &&
            this.Plugin.getConfig().field.enabled
        );
    }

    // this method calls the content of the Control function
    async createControl(ctrlObj: JQuery, settings: IBaseControlOptions) {
        if (
            settings &&
            settings.fieldType == this.Plugin.getConfig().field.fieldType &&
            this.Plugin.getConfig().field.enabled
        ) {
            const baseControl: ControlCoreBase<
                IPluginFieldHandler<IPluginFieldValueBase>,
                IPluginFieldValueBase
            > = await this.Plugin.getControlAsync(ctrlObj);
            ctrlObj.getController = () => {
                return baseControl;
            };
            baseControl.init(<IControlOptions>settings);
        }
    }

    getFieldConfigOptions(): IFieldDescription[] {
        return [this.Plugin.getConfig().field.fieldConfigOptions];
    }

    // TODO: initPrinting is called in the constructor...which can't be made to "await" it.
    // Is that a problem?
    async initPrintingAsync() {
        if (this.Plugin.getConfig().field.enabled) {
            PrintProcessor.addFunction(
                PrintProcessor.getFieldFunctionId(this.Plugin.getConfig().field.fieldType),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore tbh, not sure why it's called with a null. TODO: change getControlAsync types or refactor
                await this.Plugin.getControlAsync(null),
            );
        }
    }

    // ------------------------------------------------ project setting page ------------------------------------------------
    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    enableProjectSetting = () => {
        return this.enabledInContext;
    };
    async getProjectSettingPagesAsync(): Promise<ISettingPage[]> {
        const pbpi = await this.Plugin.getProjectSettingsPageAsync();
        if (!this.enableProjectSetting() || !this.Plugin.getConfig().projectSettingsPage.enabled) {
            return [];
        } else {
            return [
                <ISettingPage>{
                    id: this.Plugin.getConfig().projectSettingsPage.id,
                    title: this.Plugin.getConfig().projectSettingsPage.title,
                    type: this.Plugin.getConfig().projectSettingsPage.type,
                    render: (_ui: JQuery) => {
                        pbpi.renderSettingPage && pbpi.renderSettingPage();
                    },
                    saveAsync: () => {
                        return pbpi.saveAsync && pbpi.saveAsync();
                    },
                },
            ];
        }
    }

    // ------------------------------------------------ server settings page  ------------------------------------------------
    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    enableServerSetting = () => {
        return this.enabledInContext;
    };

    async getCustomerSettingPagesAsync(): Promise<ISettingPage[]> {
        const pbpi = await this.Plugin.getServerSettingsPageAsync();
        if (!this.enableServerSetting() || !this.Plugin.getConfig().customerSettingsPage.enabled) {
            return [];
        } else {
            return [
                <ISettingPage>{
                    id: this.Plugin.getConfig().customerSettingsPage.id,
                    title: this.Plugin.getConfig().customerSettingsPage.title,
                    type: this.Plugin.getConfig().customerSettingsPage.type,
                    render: (_ui: JQuery) => {
                        pbpi.renderSettingPage && pbpi.renderSettingPage();
                    },
                    saveAsync: () => {
                        return pbpi.saveAsync && pbpi.saveAsync();
                    },
                },
            ];
        }
    }

    // ------------------------------------------------ project dashboard / or folder dashboard  ------------------------------------------------

    /** can be overwritten by plugin to enable or disable functionality based on what is selected/configured */
    enableDashboardAsync = async () => {
        return this.enabledInContext;
    };

    async getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        let pages = [];
        if ((await this.enableDashboardAsync()) && this.Plugin.getConfig().dashboard.enabled) {
            pages.push({
                id: this.Plugin.getConfig().dashboard.id || "",
                title: this.Plugin.getConfig().dashboard.title || "",
                folder: this.Plugin.getConfig().dashboard.parent,
                order: this.Plugin.getConfig().dashboard.order,
                icon: this.Plugin.getConfig().dashboard.icon,
                usesFilters: true,
                render: async (_options: IPluginPanelOptions) => {
                    const gd = await this.Plugin.getDashboardAsync();
                    gd.renderProjectPage();
                },
            });
        }
        return pages;
    }

    // ------------------------------------------------ menu items  ------------------------------------------------
    // Forwards to the Plugin object
    getTinyMenuItems(editor: unknown): ITinyMenu[] {
        if (this.Plugin.getTinyMenuItems) return this.Plugin?.getTinyMenuItems(editor);
        return [];
    }
    getUserMenuItems(): IPluginMenuAction[] {
        if (this.Plugin.getUserMenuItems) return this.Plugin.getUserMenuItems();
        return [];
    }
    getConfigUserMenuItems(): IPluginMenuAction[] {
        if (this.Plugin.getConfigUserMenuItems) return this.Plugin.getConfigUserMenuItems();
        return [];
    }
    getProjectMenuItems(): IPluginMenuAction[] {
        if (this.Plugin.getProjectMenuItems) return this.Plugin.getProjectMenuItems();
        return [];
    }
    getCustomSearches(): IPluginSearch[] {
        if (this.Plugin.getCustomSearches) return this.Plugin.getCustomSearches();
        return [];
    }

    // ------------------------------------------------ plugin description (used by CI) ------------------------------------------------

    getPluginName() {
        return this.Plugin.PLUGIN_NAME;
    }

    getPluginVersion() {
        return this.Plugin.PLUGIN_VERSION;
    }
}
