import FormData from "form-data";
import { IFileUploadProgress } from "../../core/common/businesslogic";
import { AddFileAck } from "../rest-api";

/**
 * Straightforward data post request.
 * TODO: Ideally our SwaggerHub wrapper should handle this case.
 * @param axiosLib the Axios library for your environment
 * @param url
 * @param headers any headers you'd like to send
 * @param progress a upload progress notification function
 * @param file a ReadStream on the file system
 * @returns a JSON object returned by the server.
 */
export async function uploadFile(
    axiosLib: unknown,
    url: string,
    headers: object,
    progress: (p: IFileUploadProgress) => void,
    file: unknown,
): Promise<AddFileAck> {
    let data = new FormData();
    data.append("file", file);

    const config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: url,
        headers: {
            ...headers,
            ...data.getHeaders(),
        },
        data: data,
        onUploadProgress: (event: IFileUploadProgress) => {
            if (progress) {
                progress(event);
            }
        },
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const response = await (<any>axiosLib).request(config);
    return response.data;
}
