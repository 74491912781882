import { IPrintFunctionParams, IPrePostProcessorExecParams } from "../../core/printinterface/PrintFunction";
import { registerProcessors } from "./registerProcessors";

export interface PostProcessor {
    run: (rendering: string, params: IPrintFunctionParams) => string;
}

export interface PreProcessor {
    run: (mf: JQuery, params: IPrintFunctionParams) => void;
}

export interface PostProcessorMap {
    [key: string]: PostProcessor;
}

export interface PreProcessorMap {
    [key: string]: PreProcessor;
}

export class PrintProcessorRegistry {
    private postProcessors: PostProcessorMap = {};
    private preProcessors: PreProcessorMap = {};

    executePost(params: IPrePostProcessorExecParams, rendering: string): string {
        const runner = this.postProcessors[params.processor];

        if (!runner) {
            return `Missing post processor: '${params.processor}'`;
        }

        return runner.run(rendering, params);
    }

    // TODO: rename mf to something more meaningful
    executePre(params: IPrePostProcessorExecParams, mf: JQuery): void {
        const runner = this.preProcessors[params.processor];

        if (!runner) {
            console.warn(`Missing pre processor: '${params.processor}'`);
            return;
        }

        return runner.run(mf, params);
    }

    registerPostProcessor(name: string, processor: PostProcessor) {
        if (this.postProcessors[name]) {
            console.warn(`Post processor with the name: "${name}" is already registered`);
            return;
        }

        this.postProcessors = {
            ...this.postProcessors,
            [name]: processor,
        };
    }

    registerPreProcessor(name: string, processor: PreProcessor) {
        if (this.preProcessors[name]) {
            console.warn(`Pre processor with the name: "${name}" is already registered`);
            return;
        }

        this.preProcessors = {
            ...this.preProcessors,
            [name]: processor,
        };
    }

    getRegisteredProcessors() {
        return {
            preProcessors: this.preProcessors,
            postProcessors: this.postProcessors,
        };
    }
}

export const printProcessorRegistry = new PrintProcessorRegistry();

registerProcessors(printProcessorRegistry);
