/* Setting interfaces */

import { IFieldDescription } from "../../core/ProjectSettings";
import { IFieldParameter, IItem, IPrintFieldParams } from "../client";
import { ControlCoreBase, IPluginFieldHandler } from "./ControlCore";
import { IDashboardParametersBase } from "../../core/common/businesslogic/FieldHandlers/Document/IDashboardParametersBase";
import {
    IDashboardPage,
    IPluginConfigForDashboard,
    IPluginCoreForDashboard,
    IPluginFeatureBase,
    IPluginFeatureDashboard,
    IPluginFieldValueBase,
    ITinyMenu,
    IPluginMenuAction,
    IPluginSearch,
} from "../../core/common/businesslogic";
import { IServerSettingsBase, IProjectSettingsBase } from "../../core/common/businesslogic";

export type {
    IDashboardParametersBase,
    IServerSettingsBase,
    IProjectSettingsBase,
    IPluginFeatureDashboard,
    IDashboardPage,
    IPluginFeatureBase,
    IPluginFieldValueBase,
    ITinyMenu,
    IPluginMenuAction,
    IPluginSearch,
};
/**
 * This file defines all the data structures which might be shared between UI components and printing
 *
 */

/** Setting for custom fields
 *
 * These allow a user to add parameters to custom field defined by the plugin
 * each time it is added to a category
 */
export interface IPluginFieldParameterBase<T extends IPluginFieldOptionsBase> extends IFieldParameter {
    /** see below */
    options: T;
}

/**  interface for the configuration options of field */
export interface IPluginFieldOptionsBase {
    // to be defined
}

/** this allows to store parameters for printing
 *
 * This parameters can be overwritten in the layout and are used by the custom section printing
 */
export interface IPluginPrintParamsBase extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

/** Description of the current plugin. Each feature can be activated/deactivated using the configuration object */
export interface IPluginConfig<SERVERSETTINGS extends IServerSettingsBase, PROJECTSETTINGS extends IProjectSettingsBase>
    extends IPluginConfigForDashboard {
    /** Field. This will add a new field type that can be used for data rendering in the main app */
    field: IPluginFeatureField;
    /** Menu tool item. This will add a new menu item in the tools menu  in the main app.*/
    menuToolItem: IPluginFeatureBase;
    /** Menu tool item. This will add a new dashboard in the main app.*/
    dashboard: IPluginFeatureDashboard;

    /** Customer setting page parameter. This will add a page in the server config in the adminConfig */
    customerSettingsPage: IPluginFeature<SERVERSETTINGS>;
    /** project setting page parameter. This will add a page per project in the adminConfig */
    projectSettingsPage: IPluginFeature<PROJECTSETTINGS>;
}

export interface IPluginFeature<T> extends IPluginFeatureBase {
    /** Type is used to determine node type in the setting tree */
    type: string;
    /** Setting name that's used by REST api to persist settings */
    settingName: string;
    /** Default settings when nothing has been save yet*/
    defaultSettings?: T;
    /** Optional help text shown under the title */
    help?: string;
    /** Optional URL describing this page */
    helpUrl?: string;
}

export interface IPluginFeatureField extends IPluginFeatureBase {
    /**  Field type id that will be use when rendering the data */
    fieldType: string;
    /**  description of  field  capabilities*/
    fieldConfigOptions: IFieldDescription;
}

export interface IPluginSettingPage<T> {
    renderSettingPage?: () => void;
    showAdvanced?: () => void;
    showSimple?: () => void;
    getSettingsDOM?: (_setting?: T) => JQuery;
    settings?: () => T;
    saveAsync?: () => JQueryDeferred<unknown>;
    paramChanged?: () => void;
    settingsOriginal?: T;
    settingsChanged?: T;
    getProject?: () => string;
}

/**
 * Interface for the tool menu item
 */
export interface ITool {
    /**
     * function called when the menu item is clicked
     * @param itemId
     */
    menuClicked(itemId: string): void;

    /**
     * functino to decide whether the menu item should be shown or not based on the currently displayed item
     * @param itemId
     */
    showMenu(itemId: string): boolean;
}

/**
 * This is the interface that plugin that use our boilerplate template must implement.
 * It is used to define the plugin capabilities and to configure the plugin.
 * The plugin is instantiated once.
 * @param SERVERSETTINGS  The type of the server settings
 * @param PROJECTSETTINGS The type of the project settings
 * @param FIELDHANDLER The fieldhandle that will be responsible for the field data validation
 * @param FIELDVALUETYPE The type of the field value. This is the type of the data that will be stored in the database
 * @param DASHBOARDPARAMS The type of the dashboard parameters that will be used to render the dashboard.
 */
export interface IExternalPlugin<
    SERVERSETTINGS extends IServerSettingsBase,
    PROJECTSETTINGS extends IProjectSettingsBase,
    FIELDHANDLER extends IPluginFieldHandler<FIELDVALUETYPE>,
    FIELDVALUETYPE extends IPluginFieldValueBase,
    DASHBOARDPARAMS extends IDashboardParametersBase,
> extends IPluginCoreForDashboard<DASHBOARDPARAMS> {
    enableToolMenu(ul: JQuery, _hook: number): unknown;
    onInitItem(_item: IItem): unknown;
    onInitProject(project: string): unknown;
    PLUGIN_VERSION: string;
    PLUGIN_NAME: string;

    // These methods are promise-based, because plugins may need to configure themselves to
    // the environment, and yet we need a concrete result from them. Environmental configuration
    // may require things like network calls.
    /**
     * Returns a page to be added to the dashboard in the main app.
     */
    getDashboardAsync(): Promise<IDashboardPage<DASHBOARDPARAMS>>;

    /**
     * Returns a page to be added to the customer settings page in the adminConfig.
     */
    getProjectSettingsPageAsync(): Promise<IPluginSettingPage<PROJECTSETTINGS>>;

    /**
     * Returns a page to be added to the server settings page in the adminConfig.
     */
    getServerSettingsPageAsync(): Promise<IPluginSettingPage<SERVERSETTINGS>>;

    /**
     * Returns a control object for a given field type. This is used to render the field in the main app.
     * @param ctrlObj
     */
    getControlAsync(ctrlObj: JQuery): Promise<ControlCoreBase<FIELDHANDLER, FIELDVALUETYPE>>;

    /**
     * Returns a tool to be added to the tools menu in the main app.
     */
    getToolAsync(): Promise<ITool>;

    /**
     * Returns a list of TinyMCE menu items to be added to the editor.
     * @param editor
     */
    getTinyMenuItems?(editor: unknown): ITinyMenu[];

    /**
     * Returns a list of menu items to be added to the user profile menu in the main app.
     */
    getUserMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of menu items to be added to the user profile menu of the config page.
     */
    getConfigUserMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of menu items to be added to the project list.
     */
    getProjectMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of custom searches to be added to the search drowpdown.
     */
    getCustomSearches?(): IPluginSearch[];

    /**
     * Returns a list of menu items to be added to the QMS user profile menu in the liveqms.
     */
    getQMSUserMenuItems?(): IPluginMenuAction[];

    /**
     * return the plugin configuration.
     */
    getConfig(): IPluginConfig<SERVERSETTINGS, PROJECTSETTINGS>;
}
export interface IDashboardParameters extends IDashboardParametersBase {}
