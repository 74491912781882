// eslint-disable-next-line no-unused-vars
import { ml } from "../../core/common/matrixlib";
import { BaseControl, IBaseControlOptions } from "../../core/common/UI/Controls/BaseControl";
import { ControlState, IItem } from "../../core/globals";
import {
    IGlobalPrintFunctionParams,
    IPrintFunction,
    IPrintFunctionParams,
} from "../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../core/printinterface/PrintProcessorInterfaces";
import { IFieldParameter } from "../../core/ProjectSettings";
import { PrintProcessor } from "../../print/src/PrintProcessor";
import {
    IPluginConfig,
    IPluginFieldOptionsBase,
    IPluginFieldParameterBase,
    IPluginFieldValueBase,
    IPluginPrintParamsBase,
    IProjectSettingsBase,
    IServerSettingsBase,
} from "./interfaces";
import { IFieldHandler } from "../../core/common/businesslogic/FieldHandlers/IFieldHandler";

/** this is info delivered by the UI when rendering the control */
export interface IControlOptions extends IBaseControlOptions {
    placeholder: string;
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldType?: string;
    fieldId?: number;
    valueChanged?: () => unknown;
    parameter?: IFieldParameter;
    fieldValue?: string; // value as stored in DB
    isItem?: boolean;
    item?: IItem;
    isForm?: boolean;
    isPrint?: boolean;
    isTooltip?: boolean;
    id?: string;
    isHistory?: number; // version shown (if known)
    type?: string;
    isFolder?: boolean;
}

export interface IPluginFieldHandler<T> extends IFieldHandler {
    getValueAsync(): Promise<T>;
    setValue(value: T): void;
}

export abstract class ControlCoreBase<T extends IPluginFieldHandler<AAA>, AAA extends IPluginFieldValueBase>
    extends BaseControl<T>
    implements IPrintFunction
{
    protected settings?: IControlOptions;
    protected editor: JQuery | null = null;

    static defaultOptions: IControlOptions = {
        placeholder: "",
        controlState: ControlState.FormView, // read only rendering
        canEdit: false, // whether data can be edited
        valueChanged: () => console.debug("Value has changed"), // callback to call if value changes
        parameter: {
            readonly: false, // can be set to overwrite the default readonly status
            allowResize: true, // allow to resize control
            hideFullscreen: false, //  hide fullscreen
        },
    };
    pluginConfig: IPluginConfig<IServerSettingsBase, IProjectSettingsBase>;

    constructor(
        pluginConfig: IPluginConfig<IServerSettingsBase, IProjectSettingsBase>,
        fieldHandler: T,
        control?: JQuery,
    ) {
        super(control ? control : $("<div>"), fieldHandler);
        this.pluginConfig = pluginConfig;
    }

    // function called from the UI to render the control
    init(options: IControlOptions) {
        // get (default) configuration + a field value
        this.settings = <IControlOptions>ml.JSON.mergeOptions(ControlCore.defaultOptions, options);

        let config = <IPluginFieldParameterBase<T>>this.settings.parameter;

        if (this.settings && this.settings.fieldValue !== null) {
            this.fieldHandler.initData(this.settings.fieldValue);
        } else {
            if (config.initialContent !== null) {
                this.fieldHandler.initData(config.initialContent);
            }
        }

        // if it has been saved before get the value - if not remember null

        // render the control header and the container for the control
        this._root.append(super.createHelp(this.settings)); // render name of
        const container = $("<div class='baseControl'>").appendTo(this._root); // create a container

        // figure out if control should be editable
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.HistoryView ||
            !this.settings.canEdit /* no rights to edit */ ||
            this.settings.parameter?.readonly /* disabled in admin*/
        ) {
            // readonly display //
            let c = this.renderControl(true, null);
            c.appendTo(container);
        } else {
            this.editor = this.renderControl(false, null);
            this.editor.appendTo(container); // the actual UI
            this.initEditor();
        }
    }

    // --------------------------- the following methods must be overwritten

    /** method to call to initialize the editor, e.g. to attach handlers to checkboxes etc */
    protected initEditor() {
        /* to be implemented */
    }

    /** this method is called by the UI to retrieve the string to be saved in the database */
    async getValueAsync() {
        return await this.fieldHandler.getData();
    }

    // --------------------------- the following methods can be overwritten if needed
    protected originalValue?: IPluginFieldValueBase;

    /** this method renders a user input field in an item.
     * @readOnly is set to true if the user cannot edit the data (e.g. in history or while printing)
     * @params are can be parameter added by the printing configuration, to configure how something should be printed
     */
    protected abstract renderControl(readOnly: boolean, params?: IPluginPrintParamsBase | null): JQuery;

    protected abstract renderPrint(
        fieldId: string,
        value: IPluginFieldValueBase,
        options: IPluginFieldOptionsBase,
        params: IPluginPrintParamsBase,
    ): JQuery;

    protected abstract renderEditor(
        fieldId: string,
        value: IPluginFieldValueBase,
        options: IPluginFieldOptionsBase,
    ): JQuery;

    /** this method is called by the UI to figure out if the control's value changed */
    async hasChangedAsync() {
        if (this.editor) {
            // read the value from the UI and parse it
            let current = await this.fieldHandler.getValueAsync();
            // there was no value stored before || it changed
            return (
                !(this.fieldHandler && current) || (!!this.originalValue && !this.isSame(current, this.originalValue))
            );
        } else {
            return false;
        }
    }

    protected abstract isSame(a: IPluginFieldValueBase, b: IPluginFieldValueBase): boolean;

    refresh() {
        console.log("Refresh has been called");
    }

    setValue(newValue: string, reset?: boolean) {
        console.log("this could be called from the outside to force a change of value");
    }

    destroy() {
        if (this.editor) {
            this.editor = null;
        }
    }

    resizeItem() {
        console.log("resizeItem has been called");
    }

    /** CUSTOM SECTION  */

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>${this.pluginConfig.field.title}</h2>
                                    <p>Options</p>
                                    <pre>
                        </pre>`;
    }

    getName() {
        return `${this.pluginConfig.field.title} field renderer`;
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IPluginPrintParamsBase>paramsIn;
        let uid = PrintProcessor.getFieldFunctionId(this.pluginConfig.field.fieldType);
        const defaults: IPluginPrintParamsBase = {
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[uid],
            ...paramsCaller,
            ...overwrites.project[uid],
            ...overwrites.section[uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        // we are printing, save the configuration and field value, to be able to call the normal UI rendering
        this.settings = <IControlOptions>{
            fieldId: Number(paramsCaller.fieldInfo.fieldId),
            parameter: paramsCaller.fieldInfo.jsonConfig,
        };
        this.fieldHandler.initData(JSON.stringify(paramsCaller.fieldInfo.jsonValue));
        let control = this.renderControl(true, params);
        return control.html();
    }
}

export abstract class ControlCore<
    T extends IPluginFieldOptionsBase,
    F extends IPluginFieldHandler<A>,
    A extends IPluginFieldValueBase,
> extends ControlCoreBase<F, A> {
    protected controlConfig?: IPluginFieldParameterBase<T>;

    protected renderControl(readOnly: boolean, params: IPluginPrintParamsBase = { class: "" }) {
        // this are the options saved in the field setting, configuring the control
        let config = <IPluginFieldParameterBase<T>>this.settings?.parameter;

        // here, we get the options defining the radio buttons
        let options: T | null = null;
        if (config && config.options) {
            // something has been saved, we take that
            options = config.options;
        } else if (this.controlConfig) {
            options = this.controlConfig.options;
        }

        if (!options) {
            return $(
                `<div>field ${this.settings?.fieldId} is not (properly) configured: no options are defined.</div>`,
            );
        }

        const data = this.fieldHandler.getData();
        // get the actual value / default value
        let value = data ? JSON.parse(data) : null;

        // check if there is a value / and if not if there is a default value
        if (value) {
            // something has been saved before
        } else if (config.initialContent) {
            value = config.initialContent ? config.initialContent : null;
        }

        if (!value) {
            console.log(`field ${this.settings?.fieldId} has no default value.`);
        }
        this.originalValue = value;
        // do the rendering
        if (readOnly) {
            return this.renderPrint("" + this.settings?.fieldId, value, options, params);
        } else {
            return this.renderEditor("" + this.settings?.fieldId, value, options);
        }
    }
}
