import {
    IFileUploadProgress,
    IFileUploadResult,
    ISearchResult,
    ISetField,
    ItemConfiguration,
} from "../../core/common/businesslogic";
import { TestManagerConfiguration } from "../../core/common/businesslogic/TestManagerConfiguration";
import { IJSONTools, ILabelManager, ILoggerTools } from "../../core/common/matrixlib/MatrixLibInterfaces";
import { IItemGet, IItemIdParts } from "../../core/globals";
import {
    AddFileAck,
    CreateReportJobAck,
    ExecuteParam,
    FancyFolder,
    FolderAnswer,
    FromTo,
    GetTodosAck,
    JobFileWithUrl,
    JobsStatusWithUrl,
} from "../rest-api";
import { IProjectNeeds, IProjectSearchOptions } from "../standalone-interfaces";
import { Category, ItemFieldMask, IFieldMaskOptions, ItemsFieldMask } from "./Category";
import { Item } from "./Item";
import { TreeFolder } from "./TreeFolder";
import { DocItem } from "./DocItem";
import { TodoTypes } from "../common/enums";

export type { IProjectContext };
export { Project };

interface IProjectContext {
    getItemConfig(): ItemConfiguration;
    getJsonTools(): IJSONTools;
    getLogger(): ILoggerTools;
    getLabelManager(): ILabelManager;
    getTestManagerConfig(): TestManagerConfiguration;
}

/**
 * The Project class offers methods to manipulate a Matrix Project on a Matrix Instance.
 * It is not meant to be created by the end user, rather the openProject() method is used on
 * a StandaloneMatrixSDK object which represents the Matrix Instance.
 */
class Project {
    private categories: Map<string, Category>;

    constructor(
        private server: IProjectNeeds,
        private name: string,
        private context: IProjectContext,
    ) {
        // Create category objects.
        this.categories = new Map<string, Category>();
        for (let c of context.getItemConfig().getCategories(false)) {
            this.categories.set(c, new Category(c, this));
        }
    }

    /**
     * Get the root TreeFolder for a Project.
     * @returns A valid TreeFolder.
     */
    async getProjectTree(): Promise<TreeFolder> {
        const that = this;
        const folders = await that.server.getFullTreeFromProject(that.name);
        // The top level folder has to be created here synthetically.
        const f: FancyFolder = {
            id: undefined,
            title: undefined,
            children: folders,
        };
        return new TreeFolder(that, f);
    }

    /**
     * Perform a search, returning IDs of Items that match.
     * @param term - a search term. Consult documentation for valid searches, including
     *     "mrql" searches.
     * @returns An array of matching item IDs.
     */
    async searchForIds(term: string): Promise<string[]> {
        return this.server.searchIdsInProject(this.name, term);
    }

    /**
     * Run a server execute command with the given payload
     * @param payload a valid ExecuteParam object
     * @returns A FolderAnswer object
     */
    async execute(payload: ExecuteParam): Promise<FolderAnswer> {
        return this.server.executeInProject(this.name, payload);
    }

    /**
     * Used to populate the rather complex ExecuteParam type. We need a mapping from clone source
     * field ids to the output category field ids.
     * @param inputFolderIds
     * @param outputCategory
     * @param reason
     * @returns A populated ExecuteParam suitable for use with the execute method.
     */
    createExecuteParamWithDefaults(inputFolderIds: string[], outputCategory: string, reason: string): ExecuteParam {
        const config = this.getItemConfig();
        const XTCconfig = config.getTestConfig();

        const outputFieldList = config.getItemConfiguration(outputCategory).fieldList;
        let fromTo: FromTo[] = [];
        // For each clone source to the output category, create a mapping.
        for (let cloneSource of XTCconfig.cloneSources) {
            const cloneSourceFields = config.getItemConfiguration(cloneSource).fieldList;
            for (let cloneSourceField of cloneSourceFields) {
                for (let outputField of outputFieldList) {
                    if (outputField.label.toLowerCase() !== "jira") {
                        if (
                            (outputField.fieldType === "test_steps_result" &&
                                cloneSourceField.fieldType == "test_steps") ||
                            outputField.label === cloneSourceField.label
                        ) {
                            fromTo.push({ fromId: cloneSourceField.id, toId: outputField.id });
                        }
                    }
                }
            }
        }
        let executeParam: ExecuteParam = {
            input: inputFolderIds,
            output: outputCategory,
            reason: reason,
            itemFieldMapping: fromTo,
        };
        return executeParam;
    }

    /**
     * Execute a more complex search, where the fields in the results can be limited.
     * @param term
     * @param options
     * @return returns an array of ISearchResult objects.
     */
    async searchRaw(term: string, options?: IProjectSearchOptions): Promise<ISearchResult[]> {
        return this.server.searchItemsInProject(this.name, term, options);
    }

    /**
     * Perform a search and return items that are initialized according to the provided mask
     * settings. This allows you to efficiently gather data from the server with only the fields
     * you need brought down.
     *
     * @param term the search term
     * @param filter by default empty string
     * @param treeOrder return results in tree order (by default false)
     * @param mask an optional mask
     * @returns an array of filled-in Item objects.
     */
    async searchForItems(
        term: string,
        filter: string = "",
        treeOrder: boolean = false,
        mask?: ItemsFieldMask,
    ): Promise<Item[]> {
        let params: IProjectSearchOptions = {
            filter: filter ?? "",
            includeLabels: mask?.getIncludeLabels() ?? true,
            includeDownlinks: mask?.getIncludeDownlinks() ?? false,
            includeUplinks: mask?.getIncludeUplinks() ?? false,
            fieldList: mask?.getFieldMaskString() ?? "*'",
            treeOrder: treeOrder,
        };
        const results: ISearchResult[] = await this.searchRaw(term, params);
        let items: Item[] = [];

        // Turn the results into Items.

        // ItemFieldMasks are unique per category. This cache allows us to avoid creating
        // a new mask for each item, a waste of memory.
        let maskCache: Map<string, ItemFieldMask> = new Map<string, ItemFieldMask>();

        for (let oneResult of results) {
            const catName: string = this.parseRef(oneResult.itemId).type;
            const cat: Category = this.getCategory(catName);

            let catMask: ItemFieldMask | null = maskCache.get(catName) || null;
            if (!catMask) {
                if (mask) {
                    if (mask.getCategoryMask(catName) != null) {
                        catMask = mask.getCategoryMask(catName);
                    } else {
                        // Create a field mask that allows all or no fields, depending on
                        // whether fields are included.
                        catMask = mask.getIncludeFields() ? cat.createFieldMask() : cat.createFieldMask([]);
                    }
                } else {
                    // If no mask was specified for the search, then we get all category fields in the
                    // item mask.
                    catMask = cat.createFieldMask();
                }
                // by this point catMask should be defined
                maskCache.set(catName, catMask!);
            }

            let iitemGet: IItemGet = {
                id: oneResult.itemId,
                type: catName,
                title: oneResult.title,
                labels: params.includeLabels ? oneResult.labels : undefined,
                version: oneResult.version,
            };
            // Deal with the links.
            if (params.includeDownlinks) {
                iitemGet.downLinks = [];
                if (oneResult.downlinks) {
                    // TODO: We don't have link titles.
                    iitemGet.downLinks = oneResult.downlinks.map((linkId) => {
                        return { to: linkId, title: "" };
                    });
                }
            }
            if (params.includeUplinks) {
                iitemGet.upLinks = [];
                if (oneResult.uplinks) {
                    // TODO: We don't have link titles.
                    iitemGet.upLinks = oneResult.uplinks.map((linkId) => {
                        return { to: linkId, title: "" };
                    });
                }
            }
            // Deal with fields.
            // by this point catMask should be defined
            for (let fieldId of catMask!.getFieldIds()) {
                let value: string = "";
                if (oneResult.fieldVal) {
                    const values = oneResult.fieldVal.filter((r) => r.id == fieldId);
                    if (values.length > 0) {
                        value = values[0].value;
                    }
                }
                iitemGet[fieldId] = value;
            }
            // creationDate is a synthetic field, but if it is available we can pass it through iitemGet
            // into Item, which knows to look for the field.
            if (oneResult["creationDate"]) {
                iitemGet["creationDate"] = oneResult["creationDate"];
            }
            // Finally, we have a filled-in iitemGet.
            // TODO: the item should probably take the master mask, so it knows if labels and up/downlinks are included.
            // by this point catMask should be defined
            items.push(new Item(cat, iitemGet, catMask!));
        }
        return items;
    }

    /**
     * Create an ItemsFieldMask for use with search functions.
     *
     * @param options A IFieldMaskOptions object. If not specified, then appropriate defaults are chosen.
     * @returns an initialized ItemsFieldMask object which can be further customized.
     */
    constructSearchFieldMask(options?: IFieldMaskOptions): ItemsFieldMask {
        return new ItemsFieldMask(options);
    }

    /**
     * Upload a file given by the url into the project.
     * @param url
     * @returns an AddFileAck structure.
     */
    uploadFile(url: string): Promise<AddFileAck> {
        return this.server.uploadFileToProject(this.name, url);
    }

    /**
     * Upload a file to the server in Node. Not suitable for call in a web browser,
     * as the necessary libraries (and access to the file system) are not available.
     *
     * @param axiosLib A pointer to your local Axios library
     * @param file Passed through to an Axios request. A fs.ReadStream object is appropriate.
     * @param progress a callback to be notified of upload progress.
     * @returns a IFileUploadResult object.
     */
    async uploadLocalFile(
        axiosLib: unknown,
        file: unknown,
        progress: (p: IFileUploadProgress) => void,
    ): Promise<AddFileAck> {
        return this.server.uploadLocalFileToProject(this.name, axiosLib, file, progress);
    }

    /**
     * Files uploaded to the server with uploadFile() or uploadLocalFile() are retrieved
     * with a special Url that depends on the Project. This method computes the url
     * correctly.
     * @param fileInfo an AddFileAck object with information about the uploaded file
     * @returns a Url pointing to the file on the server
     */
    computeFileUrl(fileInfo: AddFileAck): string {
        const [baseUrl, baseRestUrl] = this.server.getUrlInfo();
        const url = `${baseRestUrl}/${this.name}/file/${fileInfo.fileId}?key=${fileInfo.key}`;
        return url;
    }

    /**
     * Returns information about an item from an id in a given project.
     * @param itemId A valid item id in the project
     * @returns The itemId decomposed into parts
     */
    parseRef(itemId: string): IItemIdParts {
        return this.server.parseRefForProject(this.name, itemId);
    }

    createItem(category: string): Item {
        if (category == "FOLDER") {
            throw new Error(`Folders should be created with method createFolder`);
        }
        return new Item(this.getCategory(category));
    }
    createDOC(): DocItem {
        return new DocItem(this.getCategory("DOC"));
    }

    /**
     * Create a folder. Every folder must contain only items of a particular type.
     * @param type
     * @returns a new Folder item of the given type.
     */
    createFolder(type: string): Item {
        let item: IItemGet = { isFolder: true, type: type, children: [] };
        return new Item(this.getCategory("FOLDER"), item);
    }

    async getItem(id: string): Promise<Item> {
        const iitem = await this.server.getItemFromProject(this.name, id);
        const category = iitem.isFolder ? this.getCategory("FOLDER") : this.getCategory(iitem.type || "");
        return new Item(category, iitem);
    }

    /** Return a DocItem from an id.
     * @param {id} id The id of the DOC */
    async getItemAsDoc(id: string): Promise<DocItem> {
        const iitem = await this.server.getItemFromProject(this.name, id);
        return new DocItem(this.getCategory("DOC"), iitem);
    }

    /**
     * Save an item into a given folder.
     * @param parentFolderId
     * @param item
     * @returns A fresh copy of the Item from the server
     */
    async putItem(parentFolderId: string, item: Item): Promise<Item> {
        let iitem = item.extractData();
        let newId: string;
        if (iitem.id) {
            // this is an update.
            // TODO(sdk): be able to query item field mask and update only requested fields.
            newId = await this.server.updateItemInProject(this.name, iitem, item.getMaxVersion());
        } else {
            // this is creation.
            newId = await this.server.createItemInProject(this.name, parentFolderId, iitem);
        }

        // For now, go back to the server and get a fresh item.
        return await this.getItem(newId);
    }

    /**
     * Update an item on the server.
     * @param item
     * @returns A fresh copy of the Item from the server.
     */
    async updateItem(item: Item): Promise<Item> {
        let iitem = item.extractData();
        if (!iitem.id) {
            throw new Error(`updateItem requires an item with an existing ID`);
        }
        // TODO(sdk): be able to query item field mask and update only requested fields.
        await this.server.updateItemInProject(this.name, iitem, item.getMaxVersion());
        return await this.getItem(iitem.id);
    }

    /**
     * Delete an Item from the project. If the Item is a folder with children, then parameter
     * {force} must be true.
     * @param itemId A valid item
     * @param force
     * @throws Error if the item is a non-empty folder and force was not specified as true.
     * @returns A promise with the string "Ok" on success.
     */
    async deleteItem(itemId: string, force?: boolean): Promise<string> {
        return this.server.deleteItemInProject(this.name, itemId, force);
    }

    /**
     * Move items in the project to a particular folder.
     * @param folderId a valid folder id within the project
     * @param itemIds an array of itemIds
     * @returns the string "Ok" on success
     */
    async moveItems(folderId: string, itemIds: string[]): Promise<string> {
        return this.server.moveItemsInProject(this.name, folderId, itemIds);
    }

    /**
     * set a field of an item in the database
     *
     * Use: await project.setField("PROC-83", "plain english", "x");
     *
     * @param itemId itemId the id of the item like "REQ-1"
     * @param fieldName name of the field
     * @param value value of the field
     * @throws Error in case of invalid itemId or fieldName
     * @returns Promise to the updated item
     */
    public async setField(itemId: string, fieldName: string, value: string): Promise<IItemGet> {
        return this.setFields(itemId, [{ fieldName: fieldName, value: value }]);
    }

    /**
     * sets multiple fields in the database
     *
     * Use: await api.setFields("PROC-83", [{fieldName:"plain english",value:"x"}]  )
     *
     * @param itemId itemId itemId the id of the item like "REQ-1"
     * @param data array of fieldName and value tupels
     * @throws Error in case of invalid id or fields
     * @returns the updated item
     */
    public async setFields(itemId: string, data: ISetField[]): Promise<IItemGet> {
        return this.server.setFieldsInProject(this.name, this.getItemConfig(), itemId, data);
    }

    /**
     * Get the TODOs for a project.
     * @param itemRef if specified, returns all todos linked to an item, regardless of user
     * @param includeDone - if true, includes done todos
     * @param includeAllUsers - if true, includes all todos for all users.
     * @param includeFuture - false by default. If true, includes future todos.
     * @returns Information on the todos.
     */
    getTodos(
        itemRef?: string,
        includeDone?: boolean,
        includeAllUsers?: boolean,
        includeFuture?: boolean,
    ): Promise<GetTodosAck> {
        return this.server.getProjectTodos(this.name, itemRef, includeDone, includeAllUsers, includeFuture);
    }

    getCategory(category: string): Category {
        if (!this.categories.has(category)) {
            throw new Error(`Cannot find category ${category} in project ${this.name}`);
        }
        return this.categories.get(category) as Category;
    }

    getName(): string {
        return this.name;
    }
    getItemConfig(): ItemConfiguration {
        return this.context.getItemConfig();
    }
    getLabelManager(): ILabelManager {
        return this.context.getLabelManager();
    }
    getTestConfig(): TestManagerConfiguration {
        return this.context.getTestManagerConfig();
    }

    /**
     * Run a hook with the given name on a project item.
     * @param itemId
     * @param hookName
     * @param body
     * @returns the return value of the hook.
     */
    async runHook(itemId: string, hookName: string, body: string): Promise<string> {
        return this.server.runHookInProject(this.name, itemId, hookName, body);
    }

    /**
     * Some server operations that return files as URLs are long-running jobs. With a
     * jobId, you can wait on their result while also getting progress updates.
     * @param jobId
     * @param progressReporter an optional callback to be notified of the job's progress.
     * @returns an array of JobFileWithUrl structures
     */
    async waitOnJobCompletion(
        jobId: number,
        progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void,
    ): Promise<JobFileWithUrl[]> {
        let generatedFiles: JobFileWithUrl[] = [];
        let jobFinished = false;

        while (!jobFinished) {
            let job: JobsStatusWithUrl = await this.server.getJobStatus(this.name, jobId);

            // Status is not always updated by every hook. We'll still consider it if present, but
            // we judge progress to be more important.
            const status = job.status ?? "Unknown";
            if (progressReporter) {
                progressReporter(jobId, job);
            }
            if (status === "Error" || (job.progress && job.progress > 100)) {
                throw new Error(`Error generating report : ${job.status}`);
            } else if (status !== "Done" && job.progress && job.progress < 100) {
                await this.sleep(500);
            } else if (job.progress == 100 || status === "Done") {
                jobFinished = true;
                if (job.jobFile) {
                    generatedFiles = job.jobFile;
                }
            }
        }
        return generatedFiles;
    }

    /**
     * For implementors of server-side hooks and other services that use the Job API.
     * @param jobId the jobId on which you are reporting progress
     * @param progress Progress (0 to 100, 200 to indicate error)
     * @param status optional status string like "Done" or "Error"
     * @returns string result
     */
    async postJobProgress(jobId: number, progress: number, status?: string): Promise<string> {
        return await this.server.postJobProgressForProject(this.name, jobId, progress, status);
    }

    /**
     * Admin permission on the server is required to successfully call this.
     * @param jobId the jobId you wish to cancel
     * @param reason the reason for cancellation
     * @returns string result
     */
    async deleteJob(jobId: number, reason: string): Promise<string> {
        return await this.server.deleteJobForProject(this.name, jobId, reason);
    }

    /**
     * Export a DOC to an external file.
     * @param type Can be one of "pdf", "html", "docx", or "odt"
     * @param docId The DOC id
     * @param progressReporter an optional callback with status updates
     * @returns a pointer to the location on the server where the file can be downloaded
     */
    async generateDocument(
        type: "pdf" | "html" | "docx" | "odt",
        docId: string,
        progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void,
    ): Promise<JobFileWithUrl[]> {
        let reportJob: CreateReportJobAck = await this.server.postProjectReport(this.name, docId, type);
        // let's assume jobId is always there
        return await this.waitOnJobCompletion(reportJob.jobId!, progressReporter);
    }

    /**
     * Download a job result
     * @param jobId
     * @param fileno
     * @param mode
     * @param format
     * @param disposition
     * @param options
     * @returns An ArrayBuffer
     */
    async downloadJobResult(
        jobId: number,
        fileno: number,
        mode?: string,
        format?: string,
        disposition?: string,
        options?: unknown,
    ): Promise<ArrayBuffer> {
        return await this.server.downloadJobResult(this.name, jobId, fileno, mode, format, disposition, options);
    }

    private async sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    createTodo(
        users: string[],
        type: TodoTypes,
        text: string,
        itemId: string,
        fieldId: number | null,
        atDate: Date,
    ): Promise<string> {
        return this.server.createTodo(this.name, users, type, text, itemId, fieldId, atDate);
    }
}
